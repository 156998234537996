<template>
    <div  id="add-leave">
      <v-snackbar color="success" top v-model="kayitYapildi">
        <span v-if="type == 'add'">Kayıt Başarılı</span>
        <span v-else>Kayıt başarı ile güncellendi. Yönlediriliyor..</span>
      </v-snackbar>
  
  
      <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
        <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
          <v-toolbar-title class="text-body-2 text-md-h6 font-weight-bold">
       
            <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
              {{ icons.mdiCalendarClock }}
            </v-icon>
            İzin Ekle
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-alert v-if="type == 'edit'" color="success"
          ><strong class="white--text">{{ id1 }}</strong>
          <span class="white--text"> numaralı kayıt üzerinde işlem yapıyorsunuz</span></v-alert
        >
  
        <!-- form -->
  
        <v-form style="padding: 20px" ref="form">
        <v-row>
            <v-col cols="12" md="3">
                <label for="izin_tipi">İzin Tipi</label>
              </v-col>
    
              <v-col cols="12" md="3">
              <!--   <v-select v-model="prod__3d_type" dense :items="three_d_items" label="Kart Tipi" outlined></v-select>-->
              
                <v-text-field  id="izin_tipi" v-model="izin_tipi" outlined dense hide-details></v-text-field>
                          
              </v-col>
            </v-row>
            <v-row>

                <v-col cols="12" md="3">
                    <label for="firstname">Maks. İzin Günü</label>
                  </v-col>
        
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="0"
                      v-model="banka_adi"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[required]"
                    ></v-text-field>
                  </v-col>
            </v-row>            
        <v-row > 
        
  
            <v-col cols="12" md="3">
              <label for="bas_tarih">Başlangıç Tarihi</label>
            </v-col>
  
            <v-col cols="12" md="3">
                
                <v-menu                 
                v-model="menu1"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"   
              >
                   <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                      id="bas_tarih"
                      v-model="computedDateFormatted"     
                      label="MM/DD/YYYY format"           
                      persistent-hint
                      :prepend-icon="icons.mdiCalendar"                     
                      v-bind="attrs"
                      v-on="on"
                      outlined dense hide-details
                    ></v-text-field>
                   </template>
        
                  <v-date-picker
                    v-model="date"
                    no-title
                    color="primary"
                    @input="menu1 = false"
                    
                  ></v-date-picker>   
                                  
              </v-menu>  
              
            </v-col>
         </v-row> 
         <v-row >        
            <v-col cols="12" md="3">
                <label for="email">Bitiş Tarihi</label>
              </v-col>
    
           
              <v-col cols="12" md="3">
                
                <v-menu                 
                v-model="menu2"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"   
              >
                   <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                      v-model="computedDateFormatted"     
                      label="MM/DD/YYYY format"           
                      persistent-hint
                      :prepend-icon="icons.mdiCalendar"                     
                      v-bind="attrs"
                      v-on="on"
                      outlined dense hide-details
                    ></v-text-field>
                   </template>
        
                  <v-date-picker
                    v-model="date"
                    no-title
                    color="primary"
                    @input="menu2 = false"
                    
                  ></v-date-picker>   
                                  
              </v-menu>  
              
            </v-col>
            </v-row> 
            <v-row >  
              <v-col cols="12" md="3">
                <label for="mobile">Yarım Gün Seçeneği</label>
              </v-col>
    
              <v-col cols="12" md="3">
              <!--   <v-select v-model="prod__3d_type" dense :items="three_d_items" label="Kart Tipi" outlined></v-select>-->
                <v-text-field v-model="renk" outlined dense hide-details></v-text-field>
              </v-col>     
            </v-row>
            <v-row >  
              <v-col cols="12" md="3">
                <label for="mobile">İzin Saatleri</label>
              </v-col>
    
              <v-col cols="12" md="3">
              <!--   <v-select v-model="prod__3d_type" dense :items="three_d_items" label="Kart Tipi" outlined></v-select>-->
                <v-text-field v-model="renk" outlined dense hide-details></v-text-field>
              </v-col>   

              <v-col cols="12" md="3">
                <!--   <v-select v-model="prod__3d_type" dense :items="three_d_items" label="Kart Tipi" outlined></v-select>-->
                  <v-text-field v-model="renk" outlined dense hide-details></v-text-field>
                </v-col>   
         </v-row>
             <v-row > 
                <v-col cols="12" md="3">
                    <label  >Açıklama</label>
                  </v-col>
        
                  <v-col cols="12" md="6">
                  <!--   <v-select v-model="prod__3d_type" dense :items="three_d_items" label="Kart Tipi" outlined></v-select>-->
                  <v-textarea v-model="notlar" label="Açıklama" rows="2" outlined></v-textarea>
                  </v-col>   
            </v-row>
        <v-row >    
  
          <v-col offset-md="3" cols="6">
            <v-btn :loading="loading" @click="validate" color="success" depressed class="white--text"
              ><span v-if="type == 'add'"> Kaydet</span>
              
              <span v-else>Güncelle</span>
              <v-icon
              dark
              right
            >
              {{ icons.mdiCheckboxMarkedCircleOutline }}
            </v-icon>
            </v-btn>
        
          </v-col>

        </v-row>
        </v-form>
      </v-card>
    </div>
  </template>
  
  <script>
    import moment from 'moment';
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import { required } from '@core/utils/validation'
  import { mdiCalendarClock, mdiWindowClose,mdiCheckboxMarkedCircleOutline } from '@mdi/js'
  import { onMounted, ref,computed,watch } from '@vue/composition-api'
  
  export default {
    setup() {
      const menu1 = ref(false)
      const menu2 = ref(false)
      const date = moment(String(new Date().toISOString().substr(0, 10))).format('DD-MM-YYYY')
      //const date = ref(new Date().toISOString().substr(0, 10))//
      const { router } = useRouter()
      const gelenBank = ref([])
      const loading = ref(false)
      const type = ref('add')
      const kayitYapildi = ref(false)
  
      const banka_adi = ref('')
      const kod = ref('')
      const ent_kodu = ref('')
      const ozel_kod = ref('')
      const banka_postkey = ref('')
      const card_brand = ref([])
      const sira = ref('')
      const renk = ref('')
      const aktif = ref(0)
      const pos_test_mode = ref(0)
      const notlar = ref('')
      
      const mobile = ref()
      const email = ref('')
      const id1 = ref(0)
      const valid = ref(true)
      const card_brand_items = [
      'Bonus',
      'World',
      'Advantage',
      'Cardfinans',
      'Axess',
      'Maximum',
      'Paraf',
      'BankkartCombo',
      'Combo',
      'Miles&Smiles',
      'Wings',
      'Sağlam Kart',
      'Happy',
    ]

     //#region DATE FORMAT
     const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')
      return `${day}-${month}-${year}`
    }
    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const parseDate = dates => {
      if (!dates) return null
      const [day,month, year] = dates.split('-')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value)) 


    watch(date, () => {
      dateFormatted = formatDate(date.value)
    })

      onMounted(() => {
        // console.log(router.currentRoute.params.id)
        // console.log(store.state.gidenBank)
        // if (!router.currentRoute.params.id) {
        //   router.push({ name: 'pos-tanimlari' })
        // }
  
        if (router.currentRoute.params.id) {
          console.log('edit')
  
          id1.value = router.currentRoute.params.id
          type.value = 'edit'
          gelenBank.value = store.state.gidenBank
          banka_adi.value = gelenBank.value.banka_adi
          aktif.value = gelenBank.value.aktif
          banka_postkey.value = gelenBank.value.banka_postkey
          kod.value = gelenBank.value.kod
          ozel_kod.value = gelenBank.value.ozel_kod
          pos_test_mode.value = gelenBank.value.pos_test_mode
          pos_turu.value = gelenBank.value.pos_turu.split(',').map(value => parseInt(value.trim()))
          renk.value = gelenBank.value.renk
          sira.value = gelenBank.value.sira
          card_brand.value = gelenBank.value.card_brand.split(',').map(item => item.trim())
          ent_kodu.value = gelenBank.value.ent_kodu
          notlar.value = gelenBank.value.notlar
          odeme_alt_yapi_firmasi.value = gelenBank.value.odeme_alt_yapi_firmasi
  
        } else {
          console.log('add')
          type.value = 'add'
        }
  
        // const id = 11096
      })
      const tumKartTipleri = () => {
        if (card_brand.value != card_brand_items) {
          card_brand.value = card_brand_items
        } else {
          card_brand.value = []
        }
      }
  
      const sendFields = () => {
        aktif.value == true ? (aktif.value = '1') : (aktif.value = '0')
        pos_test_mode.value == true ? (pos_test_mode.value = '1') : (pos_test_mode.value = '0')
  
        store
          .dispatch('postMethod', {
            method: 'savePosDefination',
            id: type.value == 'edit' ? router.currentRoute.params.id : '',
            'field[banka_adi]': banka_adi.value,
            'field[kod]': kod.value,
            'field[ent_kodu]': ent_kodu.value,
            'field[ozel_kod]': ozel_kod.value,
            'field[banka_postkey]': banka_postkey.value,
            'field[card_brand]': card_brand.value,
            'field[sira]': sira.value,
            'field[renk]': renk.value,
            'field[aktif]': aktif.value,
            'field[pos_test_mode]': pos_test_mode.value,
            'field[notlar]': notlar.value,
            'field[pos_turu]': pos_turu.value,
            'pos[prod][client_id]': prod_client_id.value,
            'pos[prod][terminal_no]': prod_terminal_no.value,
            'pos[prod][name]': prod_name.value,
            'pos[prod][pass]': prod_pass.value,
            'pos[prod][store_key]': prod_store_key.value,
            'pos[prod][api_url]': prod_api_url.value,
            'pos[prod][ucd_url]': prod_ucd_url.value,
            'pos[prod][mpi_url]': prod_mpi_url.value,
            'pos[prod][posnetid]': prod_posnetid.value,
            'pos[prod][keyxcip]': prod_keyxcip.value,
            'pos[prod][_3d_type]': prod__3d_type.value,
            'pos[test][client_id]': test_client_id.value,
            'pos[test][terminal_no]': test_terminal_no.value,
            'pos[test][name]': test_name.value,
            'pos[test][pass]': test_pass.value,
            'pos[test][store_key]': test_store_key.value,
            'pos[test][api_url]': test_api_url.value,
            'pos[test][ucd_url]': test_ucd_url.value,
            'pos[test][mpi_url]': test_mpi_url.value,
            'pos[test][posnetid]': test_posnetid.value,
            'pos[test][keyxcip]': test_keyxcip.value,
            'pos[test][_3d_type]': test__3d_type.value,
          })
  
          .then(response => {
            console.log(response)
            if (response.error == 0) {
              kayitYapildi.value = true
              setTimeout(() => {
                router.push({ name: 'pos-tanimlari' })
              }, 1500)
            } else {
              Vue.swal({
                title: 'Hata',
                text: response.msg,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
              loading.value = false
            }
  
            // if (response.error == 0) {
            //
            // } else {
  
            // }
          })
      }
  
      const validate = () => {
        loading.value = true
        form.value.validate()
        console.log(form.value.validate())
        if (form.value.validate() == false) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          loading.value = false
        } else {
          console.log('devam')
          sendFields()
        }
      }
  
      const form = ref(null)
  
      
  
  
      return {
        kayitYapildi,
        loading,
        banka_adi,
        kod,
        ent_kodu,
        id1,
        ozel_kod,
        banka_postkey,
        sira,
        renk,
        aktif,
        pos_test_mode,
        notlar,      
       
        sendFields,        
        valid,        
        gelenBank,
        form,
        required,
        email,
        tumKartTipleri,
        
        mobile,
        card_brand,
        card_brand_items,
        type,
        validate,

        date,
      parseDate,
      formatDate,
      menu1,
      menu2,
      computedDateFormatted,
      dateFormatted,

  
        icons: {
            mdiCalendarClock,
          mdiWindowClose,mdiCheckboxMarkedCircleOutline
        },
      }
    },
  }
  </script>
  
  <style>
  /* Tüm label'ların rengini değiştirmek için geniş bir kapsama sahip bir stil */
  label {
    color: rgb(48, 26, 26);
  }
  .v-application .primary--text {
    color: var(--v-secondary-base) !important;
    caret-color: var(--v-secondary-base) !important;
  }
  </style>
  